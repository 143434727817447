<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require(`@/assets/images/logos/logo-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              max-height="auto"
              max-width="350px"
              alt="logo"
              contain
              class=""
            ></v-img>
          </router-link>
        </v-card-title>

        <v-card-text>
          <p class="text-2xl font-weight-light text--primary text-center mb-0">Seja bem-vindo!</p>
          <p class="text-center mb-2">Faça o login na sua conta para iniciar.</p>
        </v-card-text>

        <v-card-text>
          <v-form>
            <v-text-field
              v-model="email"
              type="email"
              outlined
              label="E-mail"
              hide-details
              class="mb-3"
              :disabled="loading"
              @keyup.enter="$refs.password.focus"
            ></v-text-field>

            <v-text-field
              ref="password"
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Senha"
              placeholder="············"
              :append-icon="isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
              hide-details
              :disabled="loading"
              @click:append="isPasswordVisible = !isPasswordVisible"
              @keyup.enter="performLogin"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <!-- <v-checkbox label="Lembrar senha" hide-details class="me-3 mt-1"> </v-checkbox> -->

              <!-- <v-btn
                text
                x-small
                link
                class="mt-1"
                @click="forgotPassword"
              >
                Esqueceu sua senha?
              </v-btn> -->
            </div>

            <v-btn block x-large color="primary" class="mt-6" :loading="loading" @click="performLogin"> Entrar </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      email: '',
      password: '',
      isPasswordVisible: false,
      loading: false,
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
    forgotPassword() {
      this.$router.push({ name: 'recover-password', query: { email: this.email } })
    },
    async performLogin() {
      try {
        this.loading = true
        await this.login({
          email: this.email,
          password: this.password,
        })

        const to = this.$route.query.redirect || '/dashboard'
        this.$router.push(to)
      } catch (e) {
        this.$toast.fire({
          title: e.message,
          icon: 'error',
        })
      } finally {
        this.loading = false
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push('/dashboard')
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
